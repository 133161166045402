import Mediator from '../../js/mixins/mediator';
import Observable from '../../js/mixins/observable';
import FSM from '../../js/mixins/fsm';

/**
 * @module Modules
 */



/**
 * A basic module to build other modules upon
 * @class Modules._BaseModule
 * @constructor
 * @uses Mixins.Mediator
 * @uses Mixins.Observable
 * @uses Mixins.FSM
 */
const BaseModule =  Mediator(Observable(FSM(class {
    /**
     * Destroys the instance and removes all observers and states
     *
     * @method destroy
     * @for Modules._BaseModule
     * @return {undefined} Nothing
     */
    destroy() {
        this.destroyObservers();
        this.destroyStates();
    }
})));



export default BaseModule;