import $ from 'jquery';
import DOMModule from '../../__base/dommodule';
import { extend } from '../../../js/utils/object';

const MAIN_CONF = {
    asNavFor: '[data-imageslider-thumbs], [data-imageslider-overlays]',

    fade: true,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                arrows: false
            }
        }
    ]
};

const OVERLAYS_CONF = {
    asNavFor: '[data-imageslider-thumbs], [data-imageslider-main]',
    arrows: false,
    dots: false,
    adaptiveHeight: true,

    fade: true,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                arrows: false
            }
        }
    ]
};

const THUMBS_CONF = {
    asNavFor: '[data-imageslider-main], [data-imageslider-overlays]',
    arrows: false,
    dots: false,
    slidesToShow: 3,
    vertical: false,

    focusOnSelect: true,

    respondTo: 'min',

    responsive: [
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            dots: false,
            slidesToShow: 3
          }
        }
    ]
};

/**
 * ImageSlider module
 * @class Modules.ImageSlider
 * @constructor
 * @extends Modules._DOMModule
 */
class ImageSlider extends DOMModule {
    constructor(options) {
        super(options);

        this.$mainSlider = this.$findChilds('[data-imageslider-main]');
        this.$thumbSlider = this.$findChilds('[data-imageslider-thumbs]');
        this.$overlaySlider = this.$findChilds('[data-imageslider-overlays]');

        this.arrowNextClass = this.$data('imageslider-arrow-next');
        this.arrowPrevClass = this.$data('imageslider-arrow-prev');
        this.showArrows = this.$data('imageslider-show-arrows') || false;
        this.showPager = this.$data('imageslider-show-pager') || false;
        this.isAutoplay = this.$data('imageslider-autoplay') || false;
        this.autoplaySpeed = this.$data('imageslider-speed') || 5000;
    }

    /**
     * Creates a new ImageSlider instance without the "new" keyword but does not
     *     initialize it
     * @method of
     * @static
     * @for Modules.ImageSlider
     * @param {node|string|configs} node Node, CSS selector or configuration object
     * @return {ImageSlider} A new instance
     * 
     * @example
     *     import ImageSlider from './modules/slider/js/imageslider';
     *
     *     const myImageSlider = ImageSlider.of('[data-widget=ImageSlider]');
     *     myImageSlider.render().startUp();
     */
    static of (node) {
        return new ImageSlider(node);
    }

    /**
     * Finds and initializes all ImageSliders on a page at once
     * @method findAll
     * @static
     * @for Modules.ImageSlider
     * @param {string} selector CSS selector to match nodes against
     * @return {array} A list of instances
     * 
     * @example
     *     import ImageSlider from './modules/slider/js/imageslider';
     *
     *     ImageSlider.findAll('[data-widget=ImageSlider]');
     */
    static findAll(selector) {
        return $(selector).toArray().map((node) => {
            const imageslider = ImageSlider.of(node);
            imageslider.render().startUp();
            return imageslider;
        });
    }

    /**
     * Does nothing at the moment.
     * @method render
     * @for Modules.ImageSlider
     * @return {this} The instance
     * 
     * @example
     *     import ImageSlider from './modules/slider/js/imageslider';
     *
     *     const myImageSlider = ImageSlider.of('[data-widget=ImageSlider]');
     *     myImageSlider.render();
     */
    render() {
        return this;
    }
    /**
     * Initializes all slick slider instances (main-slider, overlay-slider, thumb-slider)
     * @method startUp
     * @for Modules.ImageSlider
     * @return {undefined} Nothing
     * 
     * @example
     *     import ImageSlider from './modules/slider/js/imageslider';
     *
     *     const myImageSlider = ImageSlider.of('[data-widget=ImageSlider]');
     *     myImageSlider.render().startUp();
     */
    startUp() {
        this.startUpMain();
        this.startUpOverlays();
        this.startUpThumbs();
    }
    /**
     * Initializes the main-slider
     * @method startUp
     * @for Modules.ImageSlider
     * @return {undefined} Nothing
     * 
     * @example
     *     import ImageSlider from './modules/slider/js/imageslider';
     *
     *     const myImageSlider = ImageSlider.of('[data-widget=ImageSlider]');
     *     myImageSlider.render().startUpMain();
     */
    startUpMain() {
        var _moreThanOne = this.$mainSlider.children().length > 1 || false;
        this.$mainSlider.slick(extend(
            MAIN_CONF, {
                asNavFor: this._asNavFor(MAIN_CONF.asNavFor),
                nextArrow: `<button type="button" class="slick-next"><span class="${this.arrowNextClass}"></span></button>`,
                prevArrow: `<button type="button" class="slick-prev"><span class="${this.arrowPrevClass}"></span></button>`,
                autoplay: this.isAutoplay,
                autoplaySpeed: this.autoplaySpeed,
                arrows: this.showArrows || false,
                dots: this.showPager || false
            })
        );
    }
    /**
     * Initializes the thumb-slider
     * @method startUp
     * @for Modules.ImageSlider
     * @return {undefined} Nothing
     * 
     * @example
     *     import ImageSlider from './modules/slider/js/imageslider';
     *
     *     const myImageSlider = ImageSlider.of('[data-widget=ImageSlider]');
     *     myImageSlider.render().startUpThumbs();
     */
    startUpThumbs() {
        this.$thumbSlider.slick(extend(
            THUMBS_CONF, {
                asNavFor: this._asNavFor(THUMBS_CONF.asNavFor)
            })
        );
    }
    /**
     * Initializes the overlay-slider
     * @method startUp
     * @for Modules.ImageSlider
     * @return {undefined} Nothing
     * 
     * @example
     *     import ImageSlider from './modules/slider/js/imageslider';
     *
     *     const myImageSlider = ImageSlider.of('[data-widget=ImageSlider]');
     *     myImageSlider.render().startUpOverlays();
     */
    startUpOverlays() {
        this.$overlaySlider.slick(extend(
            OVERLAYS_CONF, {
                asNavFor: this._asNavFor(OVERLAYS_CONF.asNavFor)
            }));
    }

    _asNavFor(selector) {
        return this.$findChilds(selector).length ? this.$findChilds(selector) : null;
    }
}

export default ImageSlider;