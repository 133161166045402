import $ from 'jquery';
import mediaQuery from '../../../js/utils/mq';
import ImageSlider from './imageslider';


const BP_FLUIDCONTENT = 'screen and (min-width: 1280px)';

const handleResizeStart = Symbol('handleResizeStart');
const handleResizeEnd = Symbol('handleResizeEnd');

const RESIZE_CHANNELS = {
    start: [], // array of strings (channel names)
    end: [] // array of strings (channel names)
}

/**
 * BGImageSlider module
 * @class Modules.BGImageSlider
 * @constructor
 * @extends Modules.ImageSlider
 */
class BGImageSlider extends ImageSlider {
    constructor(options) {
        super(options);
    }
    /**
     * Creates a new BGImageSlider instance without the "new" keyword but does not
     *     initialize it
     * @method of
     * @static
     * @for Modules.BGImageSlider
     * @param {node|string|configs} node Node, CSS selector or configuration object
     * @return {BGImageSlider} A new instance
     * 
     * @example
     *     import BGImageSlider from './modules/slider/js/bgimageslider';
     *
     *     const myBGImageSlider = BGImageSlider.of('[data-widget=BGImageSlider]');
     *     myBGImageSlider.render().startUp();
     */
    static of (node) {
        return new BGImageSlider(node);
    }

    /**
     * Finds and initializes all BGImageSliders on a page at once
     * @method findAll
     * @static
     * @for Modules.BGImageSlider
     * @param {string} selector CSS selector to match nodes against
     * @return {array} A list of instances
     * 
     * @example
     *     import BGImageSlider from './modules/slider/js/bgimageslider';
     *
     *     BGImageSlider.findAll('[data-widget=BGImageSlider]');
     */
    static findAll(selector) {
        return $(selector).toArray().map((node) => {
            const bgimageslider = BGImageSlider.of(node);
            bgimageslider.render().startUp();
            return bgimageslider;
        });
    }

    /**
     * Does nothing at the moment.
     * @method render
     * @for Modules.BGImageSlider
     * @return {this} The instance
     * 
     * @example
     *     import BGImageSlider from './modules/slider/js/bgimageslider';
     *
     *     const myBGImageSlider = BGImageSlider.of('[data-widget=BGImageSlider]');
     *     myBGImageSlider.render();
     */
    render() {
        return this;
    }
    /**
     * Initializes all slick slider instances (main-slider, overlay-slider, thumb-slider)
     * @method startUp
     * @for Modules.BGImageSlider
     * @return {undefined} Nothing
     * 
     * @example
     *     import BGImageSlider from './modules/slider/js/bgimageslider';
     *
     *     const myBGImageSlider = BGImageSlider.of('[data-widget=BGImageSlider]');
     *     myBGImageSlider.render().startUp();
     */
    startUp () {
        super.startUp();

        this.resizeHandlersOn(RESIZE_CHANNELS.start, RESIZE_CHANNELS.end);
    }
    /**
     * The images of a slide in a BGSliderImage instances are CSS backgroundimages.
     * If there are elements with CSS transitions on resizing properties that cause 
     * the slider to change its size, those elements should publish their resizing
     * actions and there resize-end actions in mediator-channels.
     * You can use this function to make your BGImgSlider instance to subscribe
     * to those channels
     *
     * @method resizeHandlersOn
     * @for Modules.BGImageSlider
     * @param {Array} resizeStartChannels, array with names of mediator channels for 
     *      transition start actions the instance should subscribe to
     * @param {Array} resizeEndChannels, array with names of mediator channels for 
     *      transitionEnd actions the instance should subscribe to
     * @return {undefined} Nothing
     * 
     * @example
     *     import BGImageSlider from './modules/slider/js/bgimageslider';
     *
     *     const myBGImageSlider = BGImageSlider.of('[data-widget=BGImageSlider]');
     *     myBGImageSlider.render().startUp();
     *
     *     myBGImageSlider.resizeHandlersOn(['Navigation::action:open'], ['Navigation::action:openTransitionEnded']);
     */
    resizeHandlersOn (resizeStartChannels, resizeEndChannels) {
        resizeStartChannels.forEach(function (channel) {
            this.subscribe(channel, this[handleResizeStart].bind(this));
        }, this);
        resizeEndChannels.forEach(function (channel) {
            this.subscribe(channel, this[handleResizeStart].bind(this));
        }, this);
    }
    /**
     * Function to call if the BGImageSlider instance has to be repositioned
     *
     * @method reposition
     * @for Modules.BGImageSlider
     * @return {undefined} Nothing
     * 
     * @example
     *     import BGImageSlider from './modules/slider/js/bgimageslider';
     *
     *     const myBGImageSlider = BGImageSlider.of('[data-widget=BGImageSlider]');
     *     myBGImageSlider.render().startUp();
     *
     *     myBGImageSlider.reposition();
     */
    reposition () {
        this.$mainSlider.slick('setPosition');
        this.$overlaySlider.slick('setPosition');
    }
    // === PRIVATE STUFF ===
    // === EVENT HANDLERS ===
    [handleResizeStart] (data) {
        clearInterval(this._animationInterval);

        if (mediaQuery(BP_FLUIDCONTENT).matches) {
            this._animationInterval = setInterval(function () {
                this._reposition();
            }.bind(this), 10);
        }
    }
    [handleResizeEnd] (data) {
        setTimeout(function () {
            clearInterval(this._animationInterval);
            this._reposition();
        }.bind(this), 200);
    }
}


export default BGImageSlider;