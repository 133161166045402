import $ from 'jquery';
import DOMModule from '../../__base/dommodule';

import DatePicker from './datepicker';


class DateRange extends DOMModule {
    constructor(options) {
        super(options);
        
        this.pickers = this.$findChilds('[data-widget="DatePicker"]').
            toArray().
            map((node) => { return DatePicker.getInstance(node.id); });

        this.connectPickers(this.pickers[0], this.pickers[1]);
    }
    /**
     * Creates a new DateRange instance without the "new" keyword but does not
     *     initialize it
     * @method of
     * @static
     * @for Modules.DateRange
     * @param {node|string|configs} node Node, CSS selector or configuration object
     * @return {DateRange} A new instance
     * 
     * @example
     *     import DateRange from './modules/datepicker/js/daterange';
     *
     *     const myDateRange = DateRange.of('[data-widget=DateRange]');
     *     myDateRange.render().startUp();
     */
    static of (node) {
        return new DateRange(node);
    }
    /**
     * Finds and initializes all DateRanges on a page at once
     * @method findAll
     * @static
     * @for Modules.DateRange
     * @param {string} selector CSS selector to match nodes against
     * @return {array} A list of instances
     * 
     * @example
     *     import DateRange from './modules/datepicker/js/daterange';
     *
     *     DateRange.findAll('[data-widget=DateRange]');
     */
    static findAll(selector) {
        return $(selector).toArray().filter((node) => {
                return !$(node).data('defer');
            }).map((node) => {
                const daterange = DateRange.of(node);
                return daterange;
            });
    }
    connectPickers(begin, end) {
        begin.on('set', function (ctx) {
            end.set('min', new Date(ctx.select), {muted: true});
        });

        end.on('set', function (ctx) {
            begin.set('max', new Date(ctx.select), {muted: true});
        });
    }
    toJSON() {
        return {
            start: this.pickers[0].toJSON(),
            end: this.pickers[1].toJSON()
        };
    }
}

export default DateRange;