/**
 * Provides string mutating functions
 *
 * @module Utils
 * @class Utils.Strings
 * @static
 */



/**
 * Removes whitespace from the beginning and the end of a given string
 *
 * @method trim
 * @for Utils.Strings
 * @param {string} x The string to trim
 * @return {string} Trimmed string
 *
 * @example
 *     import {trim} from './utils/string';
 *     
 *     var str = '   a string   ';
 *
 *     trim(str);
 *     // -> 'a string'
 *     
 */
export const trim = (x) => `${x}`.trim();

/**
 * Removes whitespace from the beginning of a given string
 *
 * @method trimLeft
 * @for Utils.Strings
 * @param {string} x The string to trim
 * @return {string} Trimmed string
 *
 * @example
 *     import {trimLeft} from './utils/string';
 *     
 *     var str = '   a string   ';
 *
 *     trimLeft(str);
 *     // -> 'a string   '
 */
export const trimLeft = (x) => `${x}`.replace(/^\s+/g, '');


/**
 * Removes whitespace from the end of a given string
 *
 * @method trimRight
 * @for Utils.Strings
 * @param {string} x The string to trim
 * @return {string} Trimmed string
 *
 * @example
 *     import {trimRight} from './utils/string';
 *     
 *     var str = '   a string   ';
 *
 *     trimRight(str);
 *     // -> '   a string';
 */
export const trimRight = (x) => `${x}`.replace(/\s+$/g, '');


/**
 * Converts string which are separated by dashes or underscores into a
 *     camelcase'd variation
 *
 * @method camelCase
 * @for Utils.Strings
 * @param {string} x The string to transform
 * @return {string} Converted string
 *
 * @example
 *     import {camelCase} from './utils/string';
 *     
 *     var str = 'this-is-the-string';
 *
 *     camelCase(str);
 *     // -> 'thisIsTheString'
 */
export const camelCase = (x) => `${x}`.replace(/(-[a-z]|_[a-z])/ig, (c) => {
    return c[1].toUpperCase();
});

/**
 * Transforms strings in camelCase or separated_by_underscores in strings
 *     which are separated-by-dashes
 *
 * @method dashCase
 * @for Utils.Strings
 * @param {string} x The string to transform
 * @return {string} Converted string
 *
 * @example
 *     import {dashCase} from './utils/string';
 *     
 *     var str = 'thisIsTheString';
 *
 *     dashCase(str);
 *     // -> 'this-is-the-string'
 */
export const dashCase = (x) => `${x}`.replace(/(_?[a-z])/ig, (c) => {
    return '-' + (c[0] === '_' ? c[1] : c[0]).toLowerCase();
});


/**
 * Transforms strings in camelCase or separated-by-dashes in strings
 *     which are separated_by_underscores
 *
 * @method underscoreCase
 * @for Utils.Strings
 * @param {string} x The string to transform
 * @return {string} Converted string
 *
 * @example
 *     import {underscoreCase} from './utils/string';
 *     
 *     var str = 'thisIsTheString';
 *
 *     underscoreCase(str);
 *     // -> 'this_is_the_string'
 */
export const underscoreCase = (x) => `${x}`.replace(/(-?[a-z])/ig, (c) => {
    return '_' + (c[0] === '-' ? c[1] : c[0]).toLowerCase();
});



export default {
    trim, trimLeft, trimRight, camelCase, dashCase, underscoreCase
}