import g from './globals';
import config from './config';

export const NAVMODE_CONF = {
    mobile: {
        open: function () {
            if (this._level > config.COLS[g.getViewport()]) {
                this.$clipNode.css('left', this._posLeftOpen);
                this.$backButtonNode.addClass('is-visible');
                this.$backButtonNode.find('.icontxt_content').html(this.getParent(config.COLS[g.getViewport()] - 1)._name);
            }
        },
        close: function () {
            this.$clipNode.css('left', this._posLeftClosed);

            if (this._level <= config.COLS[g.getViewport()] + 1) {
                this.$backButtonNode.removeClass('is-visible');
            } else {
                this.$backButtonNode.find('.icontxt_content').html(this.getParent(config.COLS[g.getViewport()])._name);
            }
        },
        _calcHeight: function () {
            var _heights = [0];

            if (this._level > config.ENTRYLEVELS[this._mode] && config.COLS[g.getViewport()] === 2) {
                _heights.push(this.getParent().getListHeight());
            }
            
            _heights.push(this.getListHeight());

            this._height = _heights.reduce(function (a, h) {
                return h == null ? a : Math.max(a, h); 
            }, 0);

        },
        _findTrigger: function () {
            return this.$rootNode.prev('[data-megadropdown-cmd="Level::jump-in"]');
        }
    },
    desktop: {
        open: function () {
            if (this._level === config.ENTRYLEVELS[this._mode] + 1) {
                this.$teaserRightNode.addClass('is-hidden');
            }

            if (this._level === config.ENTRYLEVELS[this._mode] + 2) {
                this.$teaserLeftNode.addClass('is-hidden');
                this.$backButtonNode.addClass('is-visible');
            }

            if (this._level >= config.COLS[g.getViewport()] + 1) {
                this.$clipNode.css('left', this._posLeftOpen);
                this.$backButtonNode.find('.icontxt_content').html(this.getParent(config.COLS[g.getViewport()] - 1)._name);
            }
            this.$overlayNode.css('top', '100%');
        },
        close: function () {
            if (this._level >= config.COLS[g.getViewport()] + 1) {
                this.$clipNode.css('left', this._posLeftClosed);
            }

            if (this._level > config.COLS[g.getViewport()] + 1) {
                this.$backButtonNode.find('.icontxt_content').html(this.getParent(config.COLS[g.getViewport()] )._name);                    
            }

            if (this._level <= config.COLS[g.getViewport()] + 1) {
                this.$teaserLeftNode.removeClass('is-hidden');
                this.$backButtonNode.removeClass('is-visible');
            }

            if (this._level <= config.COLS[g.getViewport()]) {
                this.$teaserRightNode.removeClass('is-hidden');
            }
                
        },
        _calcHeight: function () {
            var _heights = [];

            switch (this._level) {
                case 1:
                    _heights.push(0);
                    break;
                case 2:
                    _heights.push(this.$teaserLeftNode.outerHeight());
                    _heights.push(this.getListHeight());
                    _heights.push(this.$teaserRightNode.outerHeight());
                    break;
                case 3:
                    _heights.push(this.$teaserLeftNode.outerHeight());
                    _heights.push(this.getListHeight());
                    _heights.push(this.getParent().getListHeight());
                    break;
                default: // when level === 4 or everything deeper than 4
                    _heights.push(this.getParent(1).getListHeight());
                    _heights.push(this.getParent().getListHeight());
                    _heights.push(this.getListHeight());
            }

            this._height = _heights.reduce(function (a, h) {
                return h == null ? a : Math.max(a, h); 
            }, 0);
        },
        _findTrigger: function () {
            if (this._level === 2) {
                return this.$overlayNode.prev('[data-megadropdown-cmd="Level::jump-in"]');
            } 

            return this.$rootNode.prev('[data-megadropdown-cmd="Level::jump-in"]');
        }
    }
};

export default { 
    NAVMODE_CONF 
};
