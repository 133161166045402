import {Mixin} from '../utils/classes';
import Mediator from '../utils/mediator';


/** 
 * @module Mixins
 */



/**
 * The Mediator utility as a mixin
 * @class Mixins.Mediator
 */
const MediatorMixin = Mixin({
    /**
     * Allows to publish information through a channel of the mediator
     *
     * @method publish
     * @for Mixins.Mediator
     * @param {string} channel The channel to publish information through
     * @param {*} data The data to send through
     * @return {this} The instance
     *
     * @example
     *     import Mediator from './mixins/mediator';
     *
     *     const MyClass = Mediator(class {
     *         constructor() {}
     *     });
     *
     *     let myInstance = new MyClass();
     *     myInstance.publish('channelName', 'foo');
     */
    publish(topic, data) {
        Mediator.publish(topic, data);
        return this;
    },
    /**
     * Allows to subscribe to a channel
     *
     * @method subscribe
     * @for Mixins.Mediator
     * @param {string} channel The channel to subscribe to
     * @param {function} handler A handler function for incoming data
     * @return {string} A subscription identifier
     *
     * @example
     *     import Mediator from './mixins/mediator';
     *
     *     const Foo = Mediator(class {
     *         constructor() {
     *             this.subscribe('BarChannel', this.handleBar, this);
     *         }
     *         handleBar(data) {
     *             console.log('Foo received:', data);
     *         }
     *     })
     * 
     *     const Bar = Mediator(class {
     *         constructor() {
     *             this.subscribe('FooChannel', this.handleFoo, this);
     *         }
     *         handleFoo(data) {
     *             console.log('Bar received:', data);
     *         }
     *     });
     *
     *     let foo = new Foo();
     *     let bar = new Bar();
     *
     *     foo.publish('FooChannel', 'Info from Foo');
     *     // logs "Bar received: Info from Foo"
     *
     *     bar.publish('BarChannel', 'Info from Bar');
     *     // logs "Foo received: Info from Bar"
     */
    subscribe(topic, handler, scope) {
        return Mediator.subscribe(topic, handler, scope);
    },
    /**
     * Allows to subscribe to a channel
     *
     * @method unsubscribe
     * @for Mixins.Mediator
     * @param {string} identifier A subscription ID returned from subscribe()
     * @return {boolean} True on success, false on failure
     *
     * @example
     *     import Mediator from './mixins/mediator';
     *
     *     const Foo = Mediator(class {
     *         constructor() {
     *             this.listens = this.subscribe('self', this.handler, this);
     *         }
     *         handler(data) {
     *             console.log('Foo received:', data);
     *         }
     *         unlisten() {
     *             return this.unsubscribe(this.listens);
     *         }
     *     })
     * 
     *     let foo = new Foo();
     *     foo.publish('self', 1);
     *     // logs "Foo received: 1"
     * 
     *     foo.unlisten()
     *
     *     foo.publish('self', 1);
     *     // nothing happens
     */
    unsubscribe(identifier) {
        return Mediator.unsubscribe(identifier);
    }
});



export default MediatorMixin;