import $ from 'jquery';
import {uuid} from '../../../js/utils/uuid';
import {forward} from '../../../js/utils/object';
import {isString} from '../../../js/utils/type';
import DOMModule from '../../__base/dommodule';

var CREATED_PICKERS = {};


/**
 * @module Modules
 */
class DatePicker extends DOMModule {
    constructor(options) {
        super(options);

        this.UUID = 'datepicker-' + uuid();
        this.$rootNode.prop('id', this.UUID);

        this.$$picker = this.$rootNode.pickadate({
            showMonthsShort: true,
            showWeekdaysFull: false,
            monthsShort: this.$data('months').split(','),
            weekdaysShort: this.$data('days').split(','),
            format: this.$data('formatting') || 'yyyy/mm/dd',
            formatSubmit: this.$data('format-submit') || this.$data('formatting') || 'yyyy/mm/dd',
            hiddenName: true,
            firstDay: this.$data('startmonday') || 0,
            container: this.$data('container') || null,
            min: DatePicker.dateFromIso(this.$data('min')),
            max: DatePicker.dateFromIso(this.$data('max'))
        }).pickadate('picker');

        forward(this, this.$$picker, [
            'set',
            'get',
            'open',
            'close',
            'clear',
            'on',
            'off',
            'trigger'
        ]);

        CREATED_PICKERS[this.UUID] = this;
    } 
    /**
     * Creates a new DatePicker instance without the "new" keyword but does not
     *     initialize it
     * @method of
     * @static
     * @for Modules.DatePicker
     * @param {node|string|configs} node Node, CSS selector or configuration object
     * @return {DatePicker} A new instance
     * 
     * @example
     *     import DatePicker from './modules/datepicker/js/datepicker';
     *
     *     const myDatePicker = DatePicker.of('[data-widget=DatePicker]');
     *     myDatePicker.render().startUp();
     */
    static of (node) {
        return new DatePicker(node);
    }
    /**
     * Finds and initializes all DatePickers on a page at once
     * @method findAll
     * @static
     * @for Modules.DatePicker
     * @param {string} selector CSS selector to match nodes against
     * @return {array} A list of instances
     * 
     * @example
     *     import DatePicker from './modules/datepicker/js/datepicker';
     *
     *     DatePicker.findAll('[data-widget=DatePicker]');
     */
    static findAll(selector) {
        return $(selector).toArray().filter((node) => {
                return !$(node).data('defer');
            }).map((node) => {
                const datepicker = DatePicker.of(node);
                return datepicker;
            });
    }

    static dateFromIso (iso) {
        if (isString(iso)) {
            var xs = iso.replace('T', '-').split(/-|:/g);
            return [
                'setFullYear',
                'setMonth',
                'setDate',
                'setHours',
                'setMinutes',
                'setSeconds'
            ].reduce(function (tstamp, method) {
                tstamp[method](parseInt(xs.shift(), 10));
                return tstamp;
            }, new Date());
        } 
    }

    static getInstance(uuid) {
        return CREATED_PICKERS[uuid] || null;
    }

    getAsDate() {
        return new Date(this.get('select'));
    }
    getAsIso() {
        return this.get('select', 'yyyy-mm-dd');
    }
    setFromDate(date) {
        if (date) {
            this.set('select', date);
        }
        return this;
    }
    setFromIso(iso) {
        return this.setFromDate(DatePicker.dateFromIso(iso));
    }
    toJSON() {
        return {date: this.getAsIso()};
    }
}


export default DatePicker;