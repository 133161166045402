import BgChanger from '../modules/bgchanger/js/bgchanger';
import AccordionList from '../modules/accordion/js/list';
import Accordion from '../modules/accordion/js/accordion';
import Anchor from '../modules/anchor/js/anchor';
import Tabbox from '../modules/tabs/js/tabs';
import ImageSlider from '../modules/slider/js/imageslider';
import BGImageSlider from '../modules/slider/js/bgimageslider';
import DatePicker from '../modules/formcontrols/js/datepicker';
import DateRange from '../modules/formcontrols/js/daterange';
import Pagination from '../modules/pagination/js/pagination';

import {default as Megadropdown} from '../modules/megadropdown/js/megadropdown';
import OffCanvasMenu from '../modules/offcanvas/js/offcanvas';

// This is the main entry file. Just import the modules to use and boot them
//   here, Grunt then automatically creates a single file from all modules and
//   saves it into the _builds directory
BgChanger.findAll('[data-rbg]');
Accordion.findAll('[data-widget=Accordion]');
AccordionList.findAll('[data-widget=AccordionList]');
Anchor.findAll('[data-widget=Anchor]');
Anchor.findAll('a[href^="#"]:not([href$="#"]):not([data-widget="Anchor"])');
Tabbox.findAll('[data-widget=Tabs]');
ImageSlider.findAll('[data-widget=ImageSlider]');
BGImageSlider.findAll('[data-widget=BGImageSlider]');
Pagination.findAll('[data-widget=Pagination]');
DatePicker.findAll('[data-widget=DatePicker]');
DateRange.findAll('[data-widget=DateRange]');

Megadropdown.findAll('[data-widget=Megadropdown]');
OffCanvasMenu.findAll('[data-widget=OffCanvasMenu]');

$(window).on('load', () => { 
    $('body').addClass('page--loaded') 
});