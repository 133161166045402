/* jshint bitwise:false */



/**
 * Generates UUIDs
 *
 * @module Utils
 * @class Utils.UUID
 * @static
 */



const CHARS = [];
for (let _iter = 0; _iter < 256; _iter += 1) {
    CHARS[_iter] = (_iter < 16 ? '0' : '') + _iter.toString(16);
}


/**
 * Creates a UUID
 *
 * @method makeUUID
 * @for Utils.UUID
 * @return {string} A uuid
 *
 * @example
 *     import {makeUUID} from './utils/uuid';
 * 
 *     makeUUID(); // -> '13ab8ss8-af43-384d-fwi1-8933k3zv'
 */
export const makeUUID = () => {
    let d0 = Math.random()*0xffffffff|0,
        d1 = Math.random()*0xffffffff|0,
        d2 = Math.random()*0xffffffff|0,
        d3 = Math.random()*0xffffffff|0;

    return CHARS[d0&0xff] +
           CHARS[d0>>8&0xff] +
           CHARS[d0>>16&0xff] +
           CHARS[d0>>24&0xff] + '-' +
           CHARS[d1&0xff] +
           CHARS[d1>>8&0xff] + '-' +
           CHARS[d1>>16&0x0f|0x40] +
           CHARS[d1>>24&0xff] + '-' +
           CHARS[d2&0x3f|0x80] +
           CHARS[d2>>8&0xff] + '-' +
           CHARS[d2>>16&0xff] +
           CHARS[d2>>24&0xff] +
           CHARS[d3&0xff] +
           CHARS[d3>>8&0xff] +
           CHARS[d3>>16&0xff] +
           CHARS[d3>>24&0xff];
}
export const uuid = makeUUID;



export default { makeUUID, uuid };
