const BP_TABLET = 'screen and (min-width: 600px)';
const BP_DESKTOP = 'screen and (min-width: 1024px)';

// navimodes
const NAVMODES = { MOB: 'mobile', DESK: 'desktop'};

// viewports
const VIEWPORTS = { S: 'small' , M: 'medium', L: 'large' };

// level of first navigation list that is shown in an overlay
const ENTRYLEVELS = { mobile: 1, desktop: 2 }

// column count of different viewports
const COLS = { small: 1,   medium: 2,  large: 3 };

export default {
    BP_TABLET,
    BP_DESKTOP,
    VIEWPORTS,
    NAVMODES,
    ENTRYLEVELS,
    COLS
};