const CURR_VIEWPORT = {
    value: null,
    get() {
        return this.value;
    },
    set(val) {
        this.value = val;
    }
}

const CURR_LEVEL = {
    value: null,
    get() {
        return this.value;
    },
    set(val) {
        this.value = val;
    }
};

const getViewport = () => {
    return CURR_VIEWPORT.get();
};

const setViewport = (val) => {
    CURR_VIEWPORT.set(val);
};

const getLevel = () => {
    return CURR_LEVEL.get();
};

const setLevel = (val) => {
    CURR_LEVEL.set(val);
};

export default {
    setViewport,
    getViewport,
    setLevel,
    getLevel
}